/*
// .site
*/

@import '../variables';
.site {
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

.site__header {
    flex-shrink: 0;
}

.site__body {
    padding-top: 10px;
    flex-grow: 1;
}

.site__footer {
    flex-shrink: 0;
}